import React from "react";
import profileImage from "assets/img/unique_max_logo.jpg"

// components

export default function CardProfile() {
  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg mt-16">
        <div className="px-6">
          <div className="flex flex-wrap justify-center">
            <div className="w-full px-4 flex justify-center">
              <div className="relative">
                <img
                  alt="Profile Picture"
                  src={profileImage}
                  className="shadow-xl rounded-full h-auto align-middle border-none absolute -m-16 -ml-20 lg:-ml-16 max-w-150-px"
                />
              </div>
            </div>
            <div className="w-full px-4 text-center mt-20">
              <div className="flex justify-center py-4 lg:pt-4 pt-8">
                <div className="mr-4 p-3 text-center">
                  <span className="text-xl font-bold block uppercase tracking-wide text-blueGray-600">
                    120
                  </span>
                  <span className="text-sm text-blueGray-400">Shipments</span>
                </div>
                <div className="mr-4 p-3 text-center">
                  <span className="text-xl font-bold block uppercase tracking-wide text-blueGray-600">
                    45
                  </span>
                  <span className="text-sm text-blueGray-400">Delivered</span>
                </div>
                <div className="lg:mr-4 p-3 text-center">
                  <span className="text-xl font-bold block uppercase tracking-wide text-blueGray-600">
                    12
                  </span>
                  <span className="text-sm text-blueGray-400">Pending</span>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center mt-12">
            <h3 className="text-xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">
              Admin Name: Rajesh Kumar
            </h3>
            <div className="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold uppercase">
              <i className="fas fa-map-marker-alt mr-2 text-lg text-blueGray-400"></i>
              Chennai, Tamil Nadu, India
            </div>
            <div className="mb-2 text-blueGray-600 mt-10">
              <i className="fas fa-briefcase mr-2 text-lg text-blueGray-400"></i>
              UPS Admin - Logistics & Shipment Management
            </div>
            <div className="mb-2 text-blueGray-600">
              <i className="fas fa-building mr-2 text-lg text-blueGray-400"></i>
              UPS Global Operations Center
            </div>
          </div>
          <div className="mt-10 py-10 border-t border-blueGray-200 text-center">
            <div className="flex flex-wrap justify-center">
              <div className="w-full lg:w-9/12 px-4">
                <p className="mb-4 text-lg leading-relaxed text-blueGray-700">
                  Rajesh Kumar oversees shipment tracking, manages delivery schedules, and ensures timely updates for the UPS Admin Portal System. With a focus on accuracy, he ensures smooth coordination between customers, drivers, and warehouse teams. His role involves managing pending shipments, delivery issues, and customer escalations. Rajesh is committed to improving operational efficiency through data-driven decision-making and continuous process improvements.
                </p>
                <a
                  href="#pablo"
                  className="font-normal text-lightBlue-500"
                  onClick={(e) => e.preventDefault()}
                >
                  Show more
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
} 
