import React from "react";
import PropTypes from "prop-types";

// components

import TableDropdown from "components/Dropdowns/TableDropdown.js";

const upsData = [
  {
    trackingNumber: "1Z12345E1512345676", 
    shipperName: "Arun Kumar", 
    destination: "Chennai, TN", 
    weight: "5 lbs", 
    deliveryDate: "2024-12-15", 
    status: "In Transit"
  }, 
  {
    trackingNumber: "1Z12345E6612345678", 
    shipperName: "Deepa R", 
    destination: "Tambaram, Chennai, TN", 
    weight: "10 lbs", 
    deliveryDate: "2024-12-20", 
    status: "Delivered"
  }, 
  {
    trackingNumber: "1Z12345E5612345679", 
    shipperName: "Karthik S", 
    destination: "Poonamallee, Chennai, TN", 
    weight: "3 lbs", 
    deliveryDate: "2024-12-10", 
    status: "Delivered"
  }, 
  {
    trackingNumber: "1Z12345E3612345680", 
    shipperName: "Meena V", 
    destination: "Guindy, Chennai, TN", 
    weight: "7 lbs", 
    deliveryDate: "2024-12-12", 
    status: "Delivered"
  }, 
  {
    trackingNumber: "1Z12345E2612345681", 
    shipperName: "Rajesh P", 
    destination: "Avadi, Chennai, TN", 
    weight: "8 lbs", 
    deliveryDate: "2024-12-18", 
    status: "In Transit"
  }, 
  {
    trackingNumber: "1Z12345E6612345682", 
    shipperName: "Priya D", 
    destination: "Perambur, Chennai, TN", 
    weight: "2 lbs", 
    deliveryDate: "2024-12-09", 
    status: "Delivered"
  }, 
  {
    trackingNumber: "1Z12345E6612345683", 
    shipperName: "Vikram M", 
    destination: "Adyar, Chennai, TN", 
    weight: "4 lbs", 
    deliveryDate: "2024-12-14", 
    status: "Delivered"
  }, 
  {
    trackingNumber: "1Z12345E6612345684", 
    shipperName: "Lakshmi G", 
    destination: "T Nagar, Chennai, TN", 
    weight: "6 lbs", 
    deliveryDate: "2024-12-21", 
    status: "In Transit"
  }, 
  {
    trackingNumber: "1Z12345E6612345685", 
    shipperName: "Suresh N", 
    destination: "Sholinganallur, Chennai, TN", 
    weight: "9 lbs", 
    deliveryDate: "2024-12-19", 
    status: "Delivered"
  }, 
  {
    trackingNumber: "1Z12345E6612345686", 
    shipperName: "Anita K", 
    destination: "Madipakkam, Chennai, TN", 
    weight: "6 lbs", 
    deliveryDate: "2024-12-17", 
    status: "In Transit"
  }
];


export default function CardTable({ color }) {
  return (
    <>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h3
                className={
                  "font-semibold text-lg " +
                  (color === "light" ? "text-blueGray-700" : "text-white")
                }
              >
                UPS Data Import
              </h3>
            </div>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          {/* UPS Data table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                {[
                  "Tracking Number", 
                  "Shipper Name", 
                  "Destination", 
                  "Weight", 
                  "Delivery Date", 
                  "Status"
                ].map((header) => (
                  <th
                    key={header}
                    className={
                      "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                      (color === "light"
                        ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                        : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                    }
                  >
                    {header}
                  </th>
                ))}
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                ></th>
              </tr>
            </thead>
            <tbody>
              {/** Sample data for UPS Data Table */}
              {upsData.map((row, index) => (
                <tr key={index}>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {row.trackingNumber}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {row.shipperName}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {row.destination}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {row.weight}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {row.deliveryDate}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    <i className={`fas fa-circle mr-2 ${row.status === 'Delivered' ? 'text-emerald-500' : 'text-orange-500'}`}></i>
                    {row.status}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
                    <TableDropdown />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

CardTable.defaultProps = {
  color: "light",
};

CardTable.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};
