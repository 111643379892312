import React from "react";

// components

import MapExample from "components/Maps/MapExample.js";
import IframeDialog from "../../components/Maps/IframeDialog";


export default function Maps() {

  const iframeUrl = "https://demo.thingsboard.io/dashboard/e0ef5360-ab57-11ef-b5a8-ed1aed9a651f?publicId=37c23ea0-4528-11ef-91aa-4b5b857befbc";
  return (
    <>
      
        <div id="maps_js" className="w-full h-96">
          <div className="relative overlay break-words bg-white h-full rounded">
          
          <IframeDialog 
          url={iframeUrl} 
        />
          </div> 
        </div> 
      </>
  );
}
