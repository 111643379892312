import React from 'react';

const IframeDialog = ({ url, onClose }) => {
  return (
    
      <div id="iframe_js" className='h-600-px'>
        {/* Iframe for loading URL */}
        <iframe 
          src={url} 
          width="100%" 
          height="100%"   
          allowFullScreen
          title="UPS dashboard"
        ></iframe>
     </div>
  );
};

export default IframeDialog;
