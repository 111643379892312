import React from "react";

// components

const upsData = [
  {
    id: "UPS_001",
    name: "UPS Server Room",
    powerUsage: "1,480 kWh",
    progress: 60,
    progressColor: "bg-red-500", // Dynamic color
    bgColor: "bg-red-200" // Background color for the progress bar
  },
  {
    id: "UPS_002",
    name: "UPS Data Center",
    powerUsage: "5,480 kWh",
    progress: 70,
    progressColor: "bg-emerald-500",
    bgColor: "bg-emerald-200"
  },
  {
    id: "UPS_003",
    name: "UPS Main Office",
    powerUsage: "4,807 kWh",
    progress: 80,
    progressColor: "bg-purple-500",
    bgColor: "bg-purple-200"
  },
  {
    id: "UPS_004",
    name: "UPS Warehouse",
    powerUsage: "3,678 kWh",
    progress: 75,
    progressColor: "bg-lightBlue-500",
    bgColor: "bg-lightBlue-200"
  },
  {
    id: "UPS_005",
    name: "UPS Backup System",
    powerUsage: "2,645 kWh",
    progress: 30,
    progressColor: "bg-orange-500",
    bgColor: "bg-orange-200"
  },
  {
    id: "UPS_006",
    name: "UPS Central Office",
    powerUsage: "3,545 kWh",
    progress: 45,
    progressColor: "bg-orange-500",
    bgColor: "bg-orange-200"
  }
];

export default function CardSocialTraffic() {
  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h3 className="font-semibold text-base text-blueGray-700">
                UPS STATS
              </h3>
            </div>
            <div className="relative w-full px-4 max-w-full flex-grow flex-1 text-right">
              <button
                className="bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
              >
                See all
              </button>
            </div>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          {/* UPS Table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead className="thead-light">
              <tr>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  UPS ID
                </th>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  UPS Name
                </th>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Power Usage
                </th>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {upsData.map((ups, index) => (
                <tr key={index}>
                  <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                    {ups.id}
                  </th>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {ups.name}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {ups.powerUsage}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    <div className="flex items-center">
                      <span className="mr-2">{ups.progress}%</span>
                      <div className="relative w-full">
                        <div className={`overflow-hidden h-2 text-xs flex rounded ${ups.bgColor}`}>
                          <div
                            style={{ width: `${ups.progress}%` }}
                            className={`shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center ${ups.progressColor}`}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
